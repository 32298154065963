import React, { Component } from 'react'
import { Link } from 'gatsby'
import slug from 'slug'
import { imgUrl } from '../util/helpers'
import styled from 'styled-components'
import { theme } from '../util/style'
import RangeThumbDetail from './range-thumb-detail'
import Image400 from './page-image-400'

export default class RangeThumb extends Component {
  render() {
    const { data } = this.props
    const { storey, title, stats } = data
    const url = `/${data.storey}-storey-${slug(data.id)}/`

    return (
      <Item>
        <Link to={url}>
          <Thumb>
            <Image400 src={data.thumb} alt={data.title} />
          </Thumb>
          <RangeThumbDetail data={{ storey, title, stats }} />
        </Link>
      </Item>
    )
  }
}

const Item = styled.div`
  margin-bottom: 3em;
  font-size: 95%;
  a {
    display: block;
    color: ${theme.colors.grey1};
    &:hover {
      img {
        opacity: 0.9;
      }
    }
  }
`

const Thumb = styled.span`
  margin-bottom: 1em;
  display: block;
  img {
    display: block;
    max-width: 100%;
  }
`
